import { Card, Tabs } from "antd";
import React from "react";
import { Link } from "react-router-dom";

function Settings({ token }) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Tabs
        defaultActiveKey={1}
        items={[
          {
            key: 1,
            label: "Интеграции",
            children: (
              <Link to={`amo_triggers?token=${token}`}>
                <Card
                  onClick={() => {}}
                  hoverable
                  style={{ width: "20%" }}
                  cover={
                    <div
                      style={{
                        display: "flex",
                        height: 200,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "lightblue",
                        color: "white",
                      }}></div>
                  }>
                  <Card.Meta
                    title={"amoCRM триггеры"}
                    description={
                      "создание, редактирование и администрирование триггеров уведомлений для salesBot amoCRM"
                    }
                  />
                </Card>
              </Link>
            ),
          },
        ]}
      />
    </div>
  );
}

export default Settings;
