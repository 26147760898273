import { Button, Drawer, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { apiRequests } from "../../../shared/api/api";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import NumericAutoComplete from "../../../shared/api/NumericAutoComplete";
// import debounce from "../../../shared/lib/utils/debounce";
// import { getDate } from "../../../shared/lib/utils/getDate";
import { MaskedInput } from "antd-mask-input";

dayjs.extend(customParseFormat);

const EditContragentDrawer = ({ contragent, abra, token, updateField }) => {
  const [isVisible, setVisible] = useState(false);
  const [date, setDate] = useState("");
  const [form] = useForm();

  const handleSave = async (values) => {
    const body = {
      name: values.name_contragent,
      inn: values.inn_contragent,
      phone: values.number_contragent.replace("+", ""),
      description: values.pin_contragent,
      contragent_type: "Покупатель",
    };

    if (date.length >= 1) {
      body["birth_date"] = date;
    }

    let contragentId = contragent;

    if (typeof contragentId === "object") {
      contragentId = contragent.key;
    }

    await apiRequests.contragents
      .put(abra, body)
      .then((res) => {
        updateField(res.data);
        setVisible(false);
      })
      .catch((e) => console.log("ERROR update contragent:", e));
  };

  const findContragent = async (id) => {
    await apiRequests.contragents.getItem(id).then((res) => {
      form.setFieldValue("name_contragent", res.data.name);
      form.setFieldValue("number_contragent", res.data.phone);
      form.setFieldValue("inn_contragent", res.data.inn);
      form.setFieldValue("pin_contragent", res.data.description);
      form.setFieldValue(
        "birthday_contragent",
        res.data.birth_date &&
          dayjs(res.data.birth_date, "YYYY-MM-DD").format("DD-MM-YYYY")
      );
    });
  };

  //   const handleDate = debounce((e) => {
  //     console.log(e);
  //     const parseDate = getDate(e);
  //     const fieldDate = dayjs(parseDate, "YYYY-MM-DD").format("DD-MM-YYYY");

  //     // Если изначальную дату (или дату уже введеную) изменили
  //     if (fieldDate === "Invalid Date") {
  //       form.setFieldValue("birthday_contragent", parseDate);
  //       setDate(dayjs(parseDate, "DD-MM-YYYY").format("YYYY-MM-DD"));
  //     } else {
  //       // Только что ввели в пустой инпут
  //       setDate(parseDate);
  //       form.setFieldValue("birthday_contragent", fieldDate);
  //     }
  //   }, 1700);

  const onOpen = async () => {
    let contragentId = abra;
    console.log(abra);
    if (typeof contragent === "object" && contragent.key) {
      contragentId = contragent.key;
    }
    await findContragent(contragentId);
    setVisible(true);
  };

  const onSelectCaPhone = (val) => {
    apiRequests.contragents.getItem(val).then((res) => {
      updateField(res.data);
      setVisible(false);
      form.resetFields();
    });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        style={{ width: "100%", marginTop: "10px" }}
        type="primary"
      >
        Редактировать
      </Button>
      <Drawer
        title="Редактирование контрагента"
        width={550}
        onClose={() => {
          setVisible(false);
        }}
        open={isVisible}
      >
        <Form onFinish={handleSave} form={form} layout={"vertical"}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Поле не может быть пустым",
              },
            ]}
            name={"name_contragent"}
            label={"Имя контрагента"}
            messageVariables={"Поле не может быть пустым"}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Поле не может быть пустым",
              },
            ]}
            name={"number_contragent"}
            label={"Номер контрагента"}
            messageVariables={"Поле не может быть пустым"}
          >
            <NumericAutoComplete
              api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
              token={token}
              by={"phone"}
              onSelect={onSelectCaPhone}
            />
          </Form.Item>
          <Form.Item
            name={"inn_contragent"}
            label={"ИНН контрагента"}
            messageVariables={"Поле не может быть пустым"}
          >
            <NumericAutoComplete
              api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
              token={token}
              by={"inn"}
              onSelect={onSelectCaPhone}
            />
          </Form.Item>
          <Form.Item
            name={"pin_contragent"}
            label={"Примечание"}
            messageVariables={"Поле не может быть пустым"}
          >
            <TextArea resize={"disabled"} rows={4} />
          </Form.Item>
          <Form.Item name={"birthday_contragent"} label={"Дата рождения"}>
            <MaskedInput
              style={{ width: "110px" }}
              mask={"00-00-0000"}
              onChange={({ maskedValue, unmaskedValue }) => {
                if (unmaskedValue.length === 8) {
                  setDate(
                    dayjs(maskedValue, "DD-MM-YYYY")
                      .format("YYYY-MM-DD")
                      .toString()
                  );
                }
              }}
            />
            {/*<DatePicker*/}
            {/*    format="DD-MM-YYYY"*/}
            {/*    onChange={(obj, dateString) => setDate(dateString)}*/}
            {/*/>*/}
            {/* <InputNumber style={{ minWidth: "110px" }} onInput={handleDate} /> */}
          </Form.Item>

          <Form.Item style={{ width: "100%", marginBottom: 10 }}>
            <Button
              htmlType="submit"
              style={{ width: "100%" }}
              type={"primary"}
            >
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default EditContragentDrawer;
