import { Button, Popconfirm, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { apiRequests } from "src/shared/api/api";
import { TriggerModalCreate } from "src/enitities/AmoTriggers";
import TriggerModalUpdate from "src/enitities/AmoTriggers/ui/TriggerModalUpdate";
import dayjs from "dayjs";

function Amo_triggers({ token }) {
  const [triggers, setTriggers] = useState([]);
  const [loadingTableTriggers, setLoadingTableTriggers] = useState(false);
  const [pageTableTriggers, setPageTableTriggers] = useState(0);

  const getTriggers = async (page) => {
    setLoadingTableTriggers(true);
    try {
      apiRequests.triggers
        .getItems({ limit: 35, offset: page * 35 })
        .then((data) => setTriggers(data.data.items))
        .finally(() => setLoadingTableTriggers(false));
    } catch (error) {
      setLoadingTableTriggers(false);
    }
  };

  useEffect(() => {
    getTriggers(pageTableTriggers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerSwitchChange = async (record, value) => {
    await apiRequests.triggers.patch(record.id, { active: value }).finally(() => getTriggers(pageTableTriggers));
  };

  const columns = [
    { title: "ID", dataIndex: "id" },
    { title: "Название", dataIndex: "name" },
    {
      title: "Пауза",
      dataIndex: "active",
      render: (_, record) => {
        return <Switch checked={record.active} onChange={(e) => handlerSwitchChange(record, e)} />;
      },
    },

    {
      title: "Создано",
      dataIndex: "created_at",
      render: (_, record) => dayjs(record.created_at).format("DD-MM-YYYY hh:mm:ss"),
    },
    {
      title: "Изменено",
      dataIndex: "updated_at",
      render: (_, record) => dayjs(record.updated_at).format("DD-MM-YYYY hh:mm:ss"),
    },
    {
      title: "Действие",
      key: "action",
      // width: 100,
      width: 160,
      render: (_, record) => {
        console.log(record);
        return triggers.length >= 1 ? (
          <>
            <Popconfirm
              title="Подтвердите удаление"
              onConfirm={async () => {
                await apiRequests.triggers
                  .deleteItem(record.id)
                  .then((data) => console.log(data))
                  .catch((err) => console.log(err))
                  .finally(() => getTriggers(pageTableTriggers));
              }}
              cancelText="Отмена"
              okText="OK">
              <Button icon={<DeleteOutlined />} style={{ marginRight: 10 }} />
            </Popconfirm>
            <TriggerModalUpdate record={record} updateListTrigger={() => getTriggers(pageTableTriggers)} />
          </>
        ) : (
          "Загрузка..."
        );
      },
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <div>
        <TriggerModalCreate updateListTrigger={() => getTriggers(pageTableTriggers)} />
      </div>
      <Table
        dataSource={triggers}
        loading={loadingTableTriggers}
        columns={columns}
        title={() => "Триггеры для amoCRM"}
        size="middle"
        pagination={{
          pageSize: 35,
          onChange: (page) => setPageTableTriggers(page),
          total: triggers?.length,
        }}
      />
    </div>
  );
}

export default Amo_triggers;
