//страница "Балансы складов"
import React, { Component } from "react";
import { Table, DatePicker, Button, Dropdown, Space, Modal, Form,Typography } from "antd";
import { DebounceSelect } from "src/shared";
import { DownOutlined } from "@ant-design/icons";
import axios from "axios";
import { v4 as uuid4 } from "uuid";
import { apiRequests } from "src/shared/api/api";
import dayjs, { utc } from "dayjs";

dayjs.extend(utc);
dayjs.tz.guess();

const { RangePicker } = DatePicker;

class WarehousesBalances extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef(null);
    this.columns = [
      {
        title: "Наименование номенклатуры",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Наименование организации",
        dataIndex: "organization_name",
        key: "organization_name",
      },
      {
        title: "Наименование склада",
        dataIndex: "warehouse_name",
        key: "warehouse_name",
      },
      {
        title: "Начальный остаток",
        dataIndex: "start_ost",
        key: "start_ost",
      },
      {
        title: "Поступление",
        dataIndex: "plus_amount",
        key: "plus_amount",
      },
      {
        title: "Расход",
        dataIndex: "minus_amount",
        key: "minus_amount",
      },
      {
        title: "Остаток",
        dataIndex: "current_amount",
        key: "current_amount",
      },
      {
        title: "Текущий остаток",
        dataIndex: "now_ost",
        key: "now_ost",
      },
      this.props.isAdmin
        ? {
            title: "Действия администратора",
            key: "action",
            width: 160,
            render: (_, record) => {
              return this.state.dataSource.length >= 1 ? (
                record.key !== 0 ? (
                  record.children ? (
                    <Dropdown
                      menu={{
                        onClick: ({ key }) => {
                          switch (parseInt(key)) {
                            case 1:
                              this.setState({ recordClear: record });
                              this.setState({ showClear: true });
                              break;
                            default:
                              break;
                          }
                        },
                        items: [
                          {
                            key: "1",
                            label: "обнулить остаток категории",
                          },
                        ],
                      }}>
                      
                      <Button type="link" onClick={(e) => e.preventDefault()}>
                        <Space>
                          выполнить <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  ) : (
                    <Dropdown
                      menu={{
                        onClick: ({ key }) => {
                          switch (parseInt(key)) {
                            case 1:
                              this.setState({ recordClear: record });
                              this.setState({ showClear: true });
                              break;
                            default:
                              break;
                          }
                        },
                        items: [
                          {
                            key: "1",
                            label: "обнулить остаток товара",
                          },
                        ],
                      }}>
                      
                      <Button type="link"  onClick={(e) => e.preventDefault()}>
                        <Space>
                          выполнить <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  )
                ) : (
                  <></>
                )
              ) : (
                "Загрузка..."
              );
            },
          }
        : {},
    ];

    this.state = {
      recordClear: null,
      loadingClear: false,
      showClear: false,
      dataSource: null,
      currWarehouse: null,
      datesArr: null,
    };
  }

  fetchWarehouse = async (name) => {
    if (name) {
      return fetch(`https://${process.env.REACT_APP_APP_URL}/api/v1/warehouses/?name=${name}&token=${this.props.token}`)
        .then((response) => response.json())
        .then((body) => {
          return body;
        })
        .then((body) => {
          // if (body.result) {
          return body.result.map((payment) => ({
            label: payment.name,
            value: payment.id,
          }));

          // }
        })
        .then((body) => {
          return body;
        });
    } else {
      return fetch(`https://${process.env.REACT_APP_APP_URL}/api/v1/warehouses/?token=${this.props.token}`)
        .then((response) => response.json())
        .then((body) => {
          return body;
        })
        .then((body) => {
          // if (body.result) {
          return body.result.map((payment) => ({
            label: payment.name,
            value: payment.id,
          }));

          // }
        })
        .then((body) => {
          return body;
        });
    }
  };

  onWarehouseSelect = (params) => {
    function addKeyData(data) {
      return data.map((item) => {
        const newItem = { ...item, keyData: uuid4() };
        if (item.children) {
          newItem.children = addKeyData(item.children);
        }
        return newItem;
      });
    }
    axios
      .get(`https://${process.env.REACT_APP_APP_URL}/api/v1/alt_warehouse_balances/`, {
        params: params,
      })
      .then((res) => {
        this.setState({
          dataSource: addKeyData(res.data.result),
        });
      });
  };

  getBalance = () => {
    const { currWarehouse, datesArr } = this.state;

    let params = { token: this.props.token, warehouse_id: currWarehouse };

    if (datesArr) {
      params.date_from = datesArr[0].unix();
      params.date_to = datesArr[1].unix();
    }

    this.onWarehouseSelect(params);
  };

  clearQuantityWarehouse = () => {
    this.formRef.current
      .validateFields()
      .then((value) => {
        this.setState({ loadingClear: true });
        if (this.state.recordClear.key) {
          apiRequests.clearQuantityBalance
            .getClearCategory(this.state.recordClear.key, {
              token: this.props.token,
              date_from: value.dateRangeClear[0].startOf("day").unix(),
              date_to: value.dateRangeClear[1].endOf("day").unix(),
              warehouse_id: this.state.currWarehouse,
            })
            .then((data) => {
              this.getBalance();
              this.setState({ loadingClear: false });
              this.setState({ showClear: false });
            });
        } else {
          apiRequests.clearQuantityBalance
            .getClearProduct(this.state.recordClear.id, {
              token: this.props.token,
              date_from: value.dateRangeClear[0].startOf("day").unix(),
              date_to: value.dateRangeClear[1].endOf("day").unix(),
              warehouse_id: this.state.currWarehouse,
            })
            .then((data) => {
              this.getBalance();
              this.setState({ loadingClear: false });
              this.setState({ showClear: false });
            });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <>
        <Modal
          open={this.state.showClear}
          onOk={this.clearQuantityWarehouse}
          title="Выполнить обнуление остатков"
          okText="Обнулить"
          cancelText="Закрыть"
          onCancel={() => this.setState({ showClear: false })}>
          <Typography>
            {this.state.recordClear && this.state.recordClear.key
              ? `Категория: ${this.state.recordClear?.name}`
              : `Товар: ${this.state.recordClear?.name}`}
          </Typography>
          <Form
            style={{ paddingTop: 24 }}
            ref={this.formRef}
            initialValues={{
              dateRangeClear: null,
              andDocuments: false,
            }}>
            <Form.Item label="период" name={"dateRangeClear"} rules={[{ required: true }]}>
              <RangePicker />
            </Form.Item>
            {/* <Form.Item label="удалить документы склада" name={"andDocuments"} rules={[{ required: true }]}>
              <Switch />
            </Form.Item> */}
          </Form>
        </Modal>
        <DebounceSelect
          // mode="multiple"
          style={{ marginBottom: 10 }}
          placeholder="Введите имя склада"
          fetchOptions={this.fetchWarehouse}
          removeIcon={null}
          onSelect={(user) => this.setState({ currWarehouse: user })}
        />
        <RangePicker style={{ marginLeft: 10 }} onChange={(dates) => this.setState({ datesArr: dates })} />
        <Button onClick={this.getBalance} style={{ marginLeft: 10 }}>
          Найти
        </Button>
        <Table rowKey={"keyData"} dataSource={this.state.dataSource} columns={this.columns} />
      </>
    );
  }
}

export default WarehousesBalances;
