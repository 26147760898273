/* eslint-disable react/jsx-no-target-blank */
import { Button, Form, message, Modal, Select, Space, Spin, Tabs } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import Timeline from "react-vis-timeline-2";
import { Timeline as EventTimeLine } from "antd";
import { RentForm } from "src/enitities/Form";
import { apiRequests } from "src/shared/api/api";
import { RentContext } from "src/shared/lib/hooks/context/getRentContext";
import "vis-timeline/styles/vis-timeline-graph2d.min.css";
import { axiosInstance } from "../../shared/api/axiosInstance";
import { FilterRent } from "src/shared";
import Typography from "antd/es/typography/Typography";

const RentalChart = ({ token }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [newItem, setNewItem] = useState({});
  const [items, setItems] = useState([]);
  const [groups, setGroups] = useState([]);
  const [docSalesId, setDocSalesId] = useState(null);
  const [contragentsIds, setContragentsIds] = useState([]);
  const [contragent, setContragent] = useState(null);
  const [contragentAccept, setContragentAccept] = useState(null);
  const [eventsBooking, setEventsBooking] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [activeKeyTab, setActiveKeyTab] = useState(0);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [pageEvents, setPageEvents] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);

  const [detailForm] = Form.useForm();
  const [createForm] = Form.useForm();
  const [settingsForm] = Form.useForm();
  const timelineRef = useRef();

  const handleAddNewItem = async () => {
    const startBooking = createForm.getFieldValue("date_range")[0];
    createForm.setFieldsValue({
      start_booking: Math.floor(new Date(startBooking).getTime() / 1000),
    });

    const endBooking = createForm.getFieldValue("date_range")[1];
    createForm.setFieldsValue({
      end_booking: Math.floor(new Date(endBooking).getTime() / 1000),
    });

    const dateBooking = createForm.getFieldValue("date_app");
    createForm.setFieldsValue({
      date_booking: Math.floor(new Date(dateBooking).getTime() / 1000),
    });

    createForm.resetFields(["date_range", "tariff", "date_app"]);

    const docs_sales_id = createForm.getFieldValue("docs_sales_id");
    createForm.setFieldValue("goods", [{ nomenclature_id: newItem.group, tariff: "Месяц" }]);
    axios
      .post(`https://${process.env.REACT_APP_APP_URL}/api/v1/booking/create?token=${token}`, [
        {
          ...createForm.getFieldValue(),
          docs_sales_id: docs_sales_id,
          goods: [{ nomenclature_id: newItem.group, tariff: "Месяц" }],
        },
      ])
      .then((response) => {
        console.log(response.data);
        if (response.data.errors.length > 0) {
          response.data.errors.forEach((error) => {
            console.log(error);
            if (error.request_id === 1) {
              message.error("Ошибка! Данное время занято другой заявкой", 3);
            }
          });
        } else {
          const newItemWithId = {
            ...newItem,
            id: String(items.length + 1),
            content: createForm.getFieldValue("title"),
          };
          setItems([...items, newItemWithId]); //пока как заготовка
          getData();
          createForm.resetFields();
          resetStates();
          setIsAddModalVisible(false);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Не удалось создать заявку", 2);
      });
  };

  const fetchNomenclatureByCategory = async (categoryId, token, treeLevel, data) => {
    const response = data.filter((i) => i.category === categoryId);

    if (response) {
      return response.map((item) => ({
        content: item.name,
        value: item.id,
        id: item.id,
        treeLevel: treeLevel,
      }));
    }
    return [];
  };

  const processTree = async (nodes, token) => {
    let result = [];
    let stack = nodes.map((node) => ({ node, level: 1 }));
    const nomIds = [];
    nodes.forEach((element) => {
      nomIds.push(element.key);
    });
    const arrayOfNomenclatures = await axiosInstance.post("/nomenclatures/", nomIds);
    console.log("", arrayOfNomenclatures.data);
    while (stack.length) {
      const { node, level } = stack.pop();
      const group = {
        content: node.name,
        value: node.key,
        id: node.key,
        treeLevel: level,
      };

      const nomenclature = await fetchNomenclatureByCategory(
        node.key,
        token,
        level + 1,
        arrayOfNomenclatures.data.result
      );
      console.log(nomenclature);

      let nestedGroups = [];
      if (node.children && node.children.length > 0) {
        console.log(node);
        stack.push(...node.children.map((child) => ({ node: child, level: level + 1 })));
        nestedGroups.push(...node.children.map((child) => child.key));
      }
      if (nomenclature.length > 0) {
        nestedGroups.push(...nomenclature.map((item) => item.id));
        result = result.concat(nomenclature);
      }

      if (nestedGroups.length > 0) {
        group.nestedGroups = nestedGroups;
      }

      result.push(group);
    }

    return result;
  };

  const fetchCategory = async (token) => {
    const response = await fetch(`https://${process.env.REACT_APP_APP_URL}/api/v1/categories_tree/?token=${token}`);
    const body = await response.json();

    if (body.result) {
      const groups = await processTree(body.result, token);
      console.log("FINAL RESULT:", groups);
      return groups;
    }

    return [];
  };

  const getData = async (params) => {
    messageApi.open({
      type: "loading",
      content: "Загрузка данных..",
      duration: 0,
    });
    try {
      const groupsFetch = await fetchCategory(token);
      setGroups(groupsFetch);

      await apiRequests.booking.getItems(params).then((res) => {
        const data = res.data;
        console.log(data);
        const newItems = data.map((item) => ({
          id: item.id,
          content: item.title ? item.title : "Заявка",
          start: new Date(item.start_booking * 1000).toISOString().toString(), // ISO формат
          end: new Date(item.end_booking * 1000).toISOString().toString(), // ISO формат
          group: item.goods[0]?.nomenclature_id,
          status_booking: item.status_booking,
          status_doc_sales: item.status_doc_sales,
        }));

        console.log(newItems);

        setItems((prevItems) => [...prevItems, ...newItems]);

        if (timelineRef.current && timelineRef.current.timeline) {
          const timeline = timelineRef.current.timeline;

          timeline.setData({ groups: groupsFetch, items: newItems });
        }
      });
      console.log(groups);
      messageApi.destroy();
      messageApi.open({
        type: "success",
        content: "Данные загружены",
      });
    } catch (error) {
      console.log(error);
      messageApi.destroy();
      messageApi.open({
        type: "error",
        content: "При загрузке данных произошла ошибка",
      });
    }
  };

  useEffect(() => {
    getData();
    if (activeKeyTab === 1) handlerGetEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetStates = () => {
    setDocSalesId(null);
    setDocSalesId(null);
    setContragentsIds([]);
    setContragent(null);
    setContragentAccept(null);
  };

  const handleChange = () => {
    const goods = detailForm.getFieldValue("goods").map((id) => ({
      nomenclature_id: id,
      tariff: "Месяц",
    }));
    detailForm.setFieldsValue({ goods });

    const startBooking = detailForm.getFieldValue("date_range")[0];
    detailForm.setFieldsValue({
      start_booking: Math.floor(new Date(startBooking).getTime() / 1000),
    });

    const endBooking = detailForm.getFieldValue("date_range")[1];
    detailForm.setFieldsValue({
      end_booking: Math.floor(new Date(endBooking).getTime() / 1000),
    });

    const dateBooking = detailForm.getFieldValue("date_app");
    detailForm.setFieldsValue({
      date_booking: Math.floor(new Date(dateBooking).getTime() / 1000),
    });

    detailForm.resetFields([
      "date_range",
      "tariff",
      "nomenclature",
      "date_app",
      "goods", //!!! Временное решение
    ]);

    apiRequests.booking.patch([detailForm.getFieldValue()]);
    getData();
    setDocSalesId(null);
    setIsModalVisible(false);
  };

  const tlOptions = {
    orientation: "top",
    // start: new Date(),
    // end: new Date(1000 * 60 * 60 * 24 + new Date().valueOf()),
    editable: true,
    horizontalScroll: true,
    zoomKey: "ctrlKey",
    zoomMin: 864000000,
    onAdd: (item, callback) => {
      // Добавление элемента в таймлайн
      callback(item);
      createForm.setFieldsValue({
        date_range: [
          dayjs(item.start),
          dayjs(
            item.end && dayjs(item.end).isAfter(dayjs(item.start).add(1, "month"))
              ? item.end
              : dayjs(item.start).add(1, "month")
          ),
        ],
      });
      setNewItem(item);
      if (timelineRef.current && timelineRef.current.timeline) {
        const timeline = timelineRef.current.timeline;
        console.log(timeline.itemsData.get());
        console.log(newItem);
        // const tempItems = [...items];
        // item.content = "Новый заказ";
        // console.log(item);
        // console.log(tempItems);
        // tempItems.push(item);
        // const timeline = timelineRef.current.timeline;
        // timeline.setData({ groups: groups, items: tempItems });
      }
      setIsAddModalVisible(true);
    },
    onMoving: (item, callback) => {
      if (item.content === "new item") {
        item.content = "Добавление заказа"; // Устанавливаем контент при растягивании
      }
      callback(item);
    },
    onMove: (item) => {
      console.log("move", item);
      const startBooking = item.start;
      const endBooking = item.end;

      const changeData = {
        id: item.id,
        start_booking: Math.floor(new Date(startBooking).getTime() / 1000),
        end_booking: Math.floor(new Date(endBooking).getTime() / 1000),
        goods: [{ nomenclature_id: item.group, tariff: "Месяц" }],
      };

      apiRequests.booking.patch([changeData]);
      getData();
    },
    onUpdate: (item, callback) => {
      callback(item);
      setSelectedItem(item);
      apiRequests.booking.getItemById(item.id).then((res) => {
        apiRequests.contragents.getItem(res.data.contragent).then((contragentData) => {
          detailForm.setFieldsValue({
            contragent: contragentData.data.id,
          });
        });
        apiRequests.contragents.getItem(res.data.contragent_accept).then((contragentData) => {
          detailForm.setFieldsValue({
            contragent_accept: contragentData.data.id,
          });
        });
        const dateApp = new Date(res.data.date_booking * 1000);
        const goods = res.data.goods.map((nom) => nom.id);

        setContragentsIds([res.data.contragent, res.data.contragent_accept]);
        setDocSalesId(res.data.docs_sales_id);
        detailForm.setFieldsValue({
          title: res.data.title,
          booking_driver_id: res.data.booking_driver_id,
          docs_sales_id: res.data.docs_sales_id,
          address: res.data.address,
          comment: res.data.comment,
          date_app: dayjs(dateApp.toISOString()),
          status_doc_sales: res.data.status_doc_sales,
          status_booking: res.data.status_booking,
          tariff: res.data.goods[0].tariff,
          goods: goods,
          id: res.data.id,
          date_range: [dayjs(item.start), dayjs(item.end)],
          nomenclature_ids: res.data.goods.map((item) => item.nomenclature_id),
        });
      });
      setIsModalVisible(true);
    },
    template: function (item) {
      const container = document.createElement("div");
      container.style =
        "padding: 5px; border-radius: 4px; display: flex; align-items: center; justify-content: space-between; width: 100%;";

      const title = document.createElement("strong");
      title.textContent = item?.content ? item.content : "Добавление заказа";

      const status = document.createElement("div");
      status.style = `${
        item && item?.status_booking
          ? "float: left; margin-right: 5px; padding-left: 4px; padding-right: 4px; padding-top: 2px; padding-bottom: 2px; border-radius: 4px; background-color: #1677ff; color: #fff;"
          : ""
      }`;
      status.textContent = item?.status_booking ? item.status_booking : "";

      const content = document.createElement("div");
      content.style = "display: flex; align-items: center;";
      content.appendChild(status);
      content.appendChild(title);

      const saleStatus = document.createElement("div");
      saleStatus.style = `background-color: ${item?.status_doc_sales ? "#fff" : ""}; color: ${
        item?.status_doc_sales
          ? item.status_doc_sales === "Оплачен"
            ? "green"
            : item.status_doc_sales === "Не оплачен"
            ? "#d17373"
            : "#1677ff"
          : ""
      } ; padding-left: 4px; padding-right: 4px; padding-top: 2px; padding-bottom: 2px; border-radius: 4px;`;
      saleStatus.textContent = item?.status_doc_sales ? item.status_doc_sales : "";

      container.appendChild(content);
      container.appendChild(saleStatus);

      return container;
    },
  };

  const handlerGetEvents = async () => {
    setLoadingEvents(true);
    await apiRequests.booking
      .getEventsByNomenclature(detailForm.getFieldValue("nomenclature_ids")[0], { limit: 5, offset: pageEvents * 5 })
      .then(({ data }) => {
        setEventsBooking((prev) => [...prev, ...data.items]);
        setTotalEvents(data.total);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoadingEvents(false));
  };

  useEffect(() => {
    if (activeKeyTab === 1) handlerGetEvents();
    if (activeKeyTab === 0) setEventsBooking([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKeyTab]);

  useEffect(() => {
    handlerGetEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageEvents]);

  const handleCancelNewItem = () => {
    if (newItem && newItem.id) {
      // Удаляем элемент из таймлайна
      if (timelineRef.current && timelineRef.current.timeline) {
        const timeline = timelineRef.current.timeline;
        // timeline.setData({ groups: groups, items: updatedItems });
        timeline.itemsData.remove(newItem.id);
      }
    }
    // Сбрасываем состояние нового элемента
    setNewItem(null);
    createForm.resetFields();
    setDocSalesId(null);
    setDocSalesId(null);
    setContragentsIds([]);
    setContragent(null);
    setContragentAccept(null);
    setIsAddModalVisible(false);
  };

  return (
    <RentContext.Provider
      value={{
        eventsBooking,
        contragent,
        setContragent,
        contragentAccept,
        setContragentAccept,
        handleCancelNewItem,
        docSalesId,
        setDocSalesId,
        contragentsIds,
        setContragentsIds,
      }}>
      {contextHolder}
      <FilterRent getData={getData} token={token} />
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "Аренда",
            children: (
              <div>
                <Timeline
                  ref={timelineRef}
                  options={tlOptions}
                  // doubleClickHandler={handleDoubleClick}
                />
                {selectedItem && (
                  <Modal
                    centered
                    title={"Детали заявки"}
                    open={isModalVisible}
                    onCancel={() => {
                      setActiveKeyTab(0);
                      setEventsBooking([]);
                      setIsModalVisible(false);
                      setDocSalesId(null);
                      setContragentsIds([]);
                      setContragent(null);
                      setContragentAccept(null);
                      detailForm.resetFields();
                      setPageEvents(0);
                    }}
                    footer={[
                      <Button
                        type="primary"
                        onClick={() => {
                          handleChange();
                          setActiveKeyTab(0);
                          setPageEvents(0);
                        }}>
                        Изменить и сохранить
                      </Button>,
                      <Button
                        danger
                        onClick={() => {
                          message.warning("В разработке", 1);
                        }}>
                        Удалить
                      </Button>,
                      <Button
                        onClick={() => {
                          setEventsBooking([]);
                          setActiveKeyTab(0);
                          setIsModalVisible(false);
                          detailForm.resetFields();
                          setPageEvents(0);
                        }}>
                        Закрыть
                      </Button>,
                    ]}>
                    <Tabs
                      activeKey={activeKeyTab}
                      onChange={(e) => {
                        setActiveKeyTab(Number(e));
                        setPageEvents(0);
                        setEventsBooking([]);
                      }}
                      items={[
                        {
                          key: 0,
                          label: "Заявка",
                          children: (
                            <RentForm selectedItem={selectedItem} form={detailForm} item={selectedItem} token={token} />
                          ),
                        },
                        {
                          key: 1,
                          label: "События",
                          style: { height: "80vh", width: "100%", overflow: "scroll", paddingTop: "12px" },
                          children: (
                            <Space align="center">
                              {!loadingEvents || eventsBooking.length !== 0 ? (
                                eventsBooking.length > 0 ? (
                                  <EventTimeLine
                                    pending={
                                      totalEvents === eventsBooking.length ? null : (
                                        <Button
                                          loading={loadingEvents}
                                          onClick={() => setPageEvents((prev) => prev + 1)}>
                                          показать еще
                                        </Button>
                                      )
                                    }
                                    pendingDot={">"}
                                    items={eventsBooking?.map((item) => ({
                                      color: item?.type === "Забрал" ? "green" : "blue",
                                      children: (
                                        <div>
                                          <Typography>{item?.type}</Typography>
                                          <Typography>
                                            дата и время: {dayjs(item?.created_at).format("DD-MM-YYYY hh:mm:ss")}
                                          </Typography>
                                          <a
                                            target="_blank"
                                            href={`https://yandex.ru/maps/?pt=${item?.longitude},${item?.latitude}&z=19&l=map`}>
                                            на карте
                                          </a>
                                        </div>
                                      ),
                                    }))}
                                  />
                                ) : (
                                  <Typography>Нет событий по выбранному товару</Typography>
                                )
                              ) : (
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                  <Spin spinning={loadingEvents} />
                                </div>
                              )}
                            </Space>
                          ),
                        },
                      ]}
                    />
                  </Modal>
                )}
                <Modal
                  centered
                  title="Добавить новый элемент"
                  open={isAddModalVisible}
                  onCancel={() => {
                    handleCancelNewItem();
                  }}
                  footer={[
                    <Button
                      type="primary"
                      onClick={() => {
                        createForm
                          .validateFields()
                          .then(handleAddNewItem)
                          .catch((e) => {
                            console.log(e);
                            message.error("Проверьте правильность заполнения полей");
                          });
                      }}>
                      Создать бронь
                    </Button>,
                    <Button
                      danger
                      onClick={() => {
                        setIsAddModalVisible(false);
                        createForm.resetFields();
                      }}>
                      Отмена
                    </Button>,
                  ]}>
                  <RentForm selectedItem={newItem} form={createForm} item={newItem} token={token} />
                </Modal>
              </div>
            ),
          },
          {
            key: "2",
            label: "Настройки",
            children: (
              <Form form={settingsForm}>
                <Form.Item
                  label="Вызываемый бот amoCRM за 7 дней до окончания непролонгированной аренды"
                  name={"call_bot_7_days"}>
                  <Select style={{ width: 300 }} />
                </Form.Item>
                <Form.Item
                  label="Вызываемый бот amoCRM за 5 дней до окончания непролонгированной аренды"
                  name={"call_bot_5_days"}>
                  <Select style={{ width: 300 }} />
                </Form.Item>
                <Form.Item
                  label="Вызываемый бот amoCRM за 3 дней до окончания непролонгированной аренды"
                  name={"call_bot_3_days"}>
                  <Select style={{ width: 300 }} />
                </Form.Item>
              </Form>
            ),
          },
        ]}
      />
    </RentContext.Provider>
  );
};

export default RentalChart;
