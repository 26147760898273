import { Select } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const searchResult = async (api, token, query) => {
  return fetch(`${api}organizations/?token=${token}&name=${query}`)
    .then((response) => response.json())
    .then((body) =>
      body.result.map((user) => ({
        label: `${user.short_name}`,
        value: user.id,
      }))
    );
};

class OrganizationAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      value: undefined,
    };
  }

  async componentDidMount() {
    const option = await searchResult(this.props.api, this.props.token, "");
    this.setState({
      options: option,
      value: this.props.nodefaultvalue
        ? null
        : this.props.value
        ? this.props.value
        : null,
    });
    if (!this.props.nodefaultvalue)
      this.setState({
        value: this.props.value ? this.props.value : option[0]?.value,
      });
    else {
      console.log(this.props.value);
      this.props.onSelect(
        this.props.value ? this.props.value : option[0]?.value
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleSelect = (value) => {
    this.setState({ value: value });
  };

  filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  render() {
    const { options, value } = this.state;

    return (
      <Select
        {...this.props}
        showSearch
        options={options}
        filterOption={this.filterOption}
        onChange={(value) => this.handleSelect(value)}
        value={value}
        notFoundContent={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              Создайте организацию в разделе:
            </div>
            <Link to={`/organizations?token=${this.props.token}`}>
              Организации
            </Link>
          </div>
        }
      ></Select>
    );
  }
}

export default OrganizationAutocomplete;
